import { AccessoryColor } from '@/enums';
import { MarketStr, RoofArticle, RoofForm, RoofModel, TileFamily } from './common';
import { addResult, addResults, Category, removeZeroQtyRows, type ResultRow } from './result';
import type { Roof } from './roof';

/** Class representing the SAFE configuration of a roof. Used to calculate all SAFE articles to add to summary */
export class SAFECalc {
  public roof: Roof;

  public selColor = AccessoryColor.None;
  public selBattenSteps = 0;
  public selMountingRail = 0;
  public calcTrackTilesForBattenSteps = false;
  public selSlipProtection = 0;
  public selRoofHatch = 0;
  public selSafetyHook = 0;
  public selSnowHook = 0;
  public selIceStopper = 0;

  public battenStepArtNo = '';
  public mountingRailArtNo = '';
  public slipProtectionArtNo = '';
  public roofHatchArtNo = '';
  public safetyHookArtNo = '';
  public snowHookArtNo = '';
  public fasteningEyeArtNo = '';
  public snowSlideObstacleArtNo = '';
  public snowSlideObstacleKonsolArtNo = '';
  public iceStopperArtNo = '';

  public useSAFE = false;
  public snowSafetyLengths: number[] = [];
  public roofBridgeLengths: number[] = [];
  public hatchSafetyLengths: number[] = [];
  public roofRailsAmount = 0;
  public snowZone = 0;
  public fasteningEyeAmount = 0;
  public selSnowSlideProtection = false;

  private calcResults = {
    snowSlideConsoles: 0,
    snowSafety: 0,
    snowSafetyConsoles: 0,
    roofBridgeLong: 0,
    roofBridgeShort: 0,
    roofBridgeConsoles: 0,
    roofRail: 0,
    roofRailConsoles: 0,
    footPlats: 0,
  };

  public constructor(roof: Roof) {
    this.roof = roof;
    this.roof.safe = this;
  }

  public getSnowSafetyArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '172420';
      case AccessoryColor.BrickRed:
        return '172424';
      case AccessoryColor.GalvanizedGreySilver:
        return '172490';
    }
    return '';
  }

  public getSnowSafetyConsoleArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170320';
      case AccessoryColor.BrickRed:
        return '170324';
      case AccessoryColor.GalvanizedGreySilver:
        return '170390';
    }
    return '';
  }

  public getRoofBridgeArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '172220';
      case AccessoryColor.BrickRed:
        return '172224';
      case AccessoryColor.GalvanizedGreySilver:
        return '172290';
    }
    return '';
  }

  public getRoofBridgeShortArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170220';
      case AccessoryColor.BrickRed:
        return '170224';
      case AccessoryColor.GalvanizedGreySilver:
        return '170290';
    }
    return '';
  }

  public getRoofBridgeConsoleArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170120';
      case AccessoryColor.BrickRed:
        return '170124';
      case AccessoryColor.GalvanizedGreySilver:
        return '170190';
    }
    return '';
  }

  public getRoofRailArtNo(): string {
    // const roofRailBase = '1726';
    // BENT-125: Temporarily change roof rail artnr to 1706xx
    const roofRailBase = '1706';
    switch (this.selColor) {
      case AccessoryColor.Black:
        return `${roofRailBase}20`;
      case AccessoryColor.BrickRed:
        return `${roofRailBase}24`;
      case AccessoryColor.GalvanizedGreySilver:
        return `${roofRailBase}90`;
    }
    return '';
  }

  public getRoofRailConsoleArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170520';
      case AccessoryColor.BrickRed:
        return '170524';
      case AccessoryColor.GalvanizedGreySilver:
        return '170590';
    }
    return '';
  }

  public getRoofHatchArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170820';
      case AccessoryColor.BrickRed:
        return '170824';
      case AccessoryColor.GalvanizedGreySilver:
        return '170890';
    }
    return '';
  }

  public getRoofHatchConsoleArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '170720';
      case AccessoryColor.BrickRed:
        return '170724';
      case AccessoryColor.GalvanizedGreySilver:
        return '170790';
    }
    return '';
  }

  public getFootPlatArtNo(): string {
    switch (this.roof.selection.tileFamily.family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
        return '170990';
      case TileFamily.Carisma:
      case TileFamily.PianoFalsatLertegel:
        return '171090';
    }
    return '';
  }

  public getTakfall() {
    if (!this.roof.mainPart) return 0;

    const takfall = this.roof.mainPart.getTakfall();
    let t = takfall.takfall / 1000;
    if (this.roof.mainPart.model === RoofModel.Mansard) t += takfall.takfall2 / 1000;
    return t;
  }

  public getTakvinkel(): number {
    if (!this.roof.mainPart) return 0;

    if (this.roof.mainPart.model === RoofModel.Standard) return this.roof.mainPart.angle;

    return Math.max(this.roof.mainPart.angle, this.roof.mainPart.angle2);
  }

  public getTakTyp(): RoofForm {
    if (this.roof?.mainPart?.form === RoofForm.Pult) return RoofForm.Pult;

    return RoofForm.Sadel;
  }

  private amountOfAccessories(lengthsArr: number[], productLength = 1, indent = 0, interval = 0) {
    let total = 0;
    for (const len of lengthsArr) {
      const length = Math.ceil(len / productLength) * productLength;
      const divideBy = interval > 0 ? interval : productLength;
      total += Math.ceil((length - indent) / divideBy) + 1;
    }
    return total;
  }

  private roofMultiplier(): number {
    const tileData = getTileData(this.roof.selection.tileFamily.family);
    return (tileData.multiplier * tileData.cc) / 1000;
  }

  public calculateMaxTakfall(): number {
    const vinkel = this.getTakvinkel();
    let my = 0;

    switch (this.getTakTyp()) {
      case RoofForm.Sadel:
        if (vinkel <= 22.5) my = 0.8 + (0.3 / 22.5) * vinkel;
        else if (vinkel <= 53.2) my = 1.1 - (0.9 / 30.7) * (vinkel - 22.5);
        else my = 0.2;

        break;
      case RoofForm.Pult:
        if (vinkel <= 30) my = 0.8;
        else if (vinkel <= 52.5) my = (0.8 * (60 - vinkel)) / 30;
        else if (vinkel <= 60) my = 0.2;
        else my = 0.0;

        break;
    }
    return (
      (1200 * 5) /
      (getTileData(this.roof.selection.tileFamily.family).maxDistanceConsole *
        this.snowZone *
        my *
        Math.cos((vinkel * Math.PI) / 180) *
        Math.sin((vinkel * Math.PI) / 180))
    );
  }

  public calculateMaxDistance(): number {
    const takfallPerRad = this.getTakfall() / this.calculateRowsSnowProtection();
    const vinkel = this.getTakvinkel();
    let my = 0;

    switch (this.getTakTyp()) {
      case RoofForm.Sadel:
        if (vinkel <= 22.5) my = 0.8 + (0.3 / 22.5) * vinkel;
        else if (vinkel <= 53.2) my = 1.1 - (0.9 / 30.7) * (vinkel - 22.5);
        else my = 0.2;

        break;
      case RoofForm.Pult:
        if (vinkel <= 30) my = 0.8;
        else if (vinkel <= 52.5) my = (0.8 * (60 - vinkel)) / 30;
        else if (vinkel <= 60) my = 0.2;
        else my = 0.0;

        break;
    }

    let maxDistance = (1200 * 5) / (this.snowZone * my * Math.cos((vinkel * Math.PI) / 180) * Math.sin((vinkel * Math.PI) / 180) * takfallPerRad);
    if (maxDistance > 1200) maxDistance = 1200;
    return maxDistance;
  }

  public calculateRowsSnowProtection() {
    return Math.ceil(this.getTakfall() / this.calculateMaxTakfall());
  }

  public calculateSnowSafety(): number {
    // Varje enhet är 2.1m lång, beräkna hur många enheter det tar för att täcka alla längder.
    // BK-245: Vi kräver att resten från alla jämt beräknade snörasskydd överstiger 50cm innan det beräknas en extra
    const amountPerRow = this.snowSafetyLengths.reduce((acc, len) => (acc += Math.ceil(Math.max(0.01, len - 0.5) / snowProtectionLength)), 0);

    const rows = this.calculateRowsSnowProtection();

    this.calcResults.snowSafety = amountPerRow * rows;
    return amountPerRow * rows;
  }

  public calculateDistanceConsole(): number {
    const tileData = getTileData(this.roof.selection.tileFamily.family);
    const maxDistance = this.calculateMaxDistance();

    const distanceConsole =
      maxDistance < tileData.maxDistanceConsole ? tileData.maxDistanceConsole / 1000 : (Math.floor(maxDistance / tileData.cc) * tileData.cc) / 1000;

    return distanceConsole;
  }

  public calculateSnowSafetyConsoles(): ResultRow[] {
    const distanceConsole = this.calculateDistanceConsole();
    const rows = this.calculateRowsSnowProtection();

    let amountPerRow = 0;
    for (const len of this.snowSafetyLengths) {
      const wholeLengths = Math.ceil((len - 0.5) / snowProtectionLength) * snowProtectionLength;
      amountPerRow += Math.ceil(Number(wholeLengths.toFixed(2)) / distanceConsole) + 1;
    }

    let amount = amountPerRow * rows;
    this.calcResults.snowSafetyConsoles = amount;

    const nPerBundle = 10;
    const bundles = Math.floor(amount / nPerBundle);
    amount = amount % nPerBundle;

    const artno = this.getSnowSafetyConsoleArtNo();
    const bundleArtno = this.getSnowSafetyConsoleArtNo() + '-10';
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: bundleArtno, total: bundles, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public async calculateSafeTrackTiles(): Promise<ResultRow[]> {
    const consoles = this.calcResults.snowSafetyConsoles + this.calcResults.roofBridgeConsoles + this.calcResults.roofRailConsoles;

    let amount = 0;
    switch (this.roof.selection.tileFamily.family) {
      case TileFamily.Carisma:
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
      case TileFamily.PianoFalsatLertegel:
        amount = consoles;
        break;
      default:
        // Exklusiv 1-kupig
        // Palema 2-kupig
        amount = consoles * 2;
    }

    amount += this.roofRailsAmount * 8;

    const results: ResultRow[] = [];
    for (const art of [
      RoofArticle.SpårPanna,
      RoofArticle.SpårPannaBörjan,
      RoofArticle.SpårPannaSlut,
      RoofArticle.SpårPannaHöger,
      RoofArticle.SpårPannaVänster,
    ]) {
      try {
        const artno = await this.roof.selection.getArticleNumber(art, false, true);
        if (artno) results.push({ art: art, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} });
      } catch (err) {
        continue;
      }
    }
    return results;
  }

  /** Calculate tracking tiles for some non-safe tiles, only used for norway */
  public async calculateTrackTiles(): Promise<ResultRow[]> {
    let trackTiles = 0, trackTilesLeft = 0, trackTilesRight = 0;

    switch (this.roof.selection.tileFamily.family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
        trackTiles = this.calcResults.snowSlideConsoles + (this.calcTrackTilesForBattenSteps ? 2 * this.selBattenSteps : 0);
        break;
      case TileFamily.Carisma:
        trackTilesRight = (this.calcTrackTilesForBattenSteps ? this.selBattenSteps : 0);
        trackTilesLeft = this.calcResults.snowSlideConsoles + (this.calcTrackTilesForBattenSteps ? this.selBattenSteps : 0);
        break;
    }

    const results: ResultRow[] = [];
    for (const [art, amount] of [
      [RoofArticle.SpårPanna, trackTiles],
      [RoofArticle.SpårPannaHöger, trackTilesRight],
      [RoofArticle.SpårPannaVänster, trackTilesLeft],
    ]) {
      try {
        const artno = await this.roof.selection.getArticleNumber(art, false, true);
        if (artno) results.push({ art: art, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} });
      } catch (err) {
        continue;
      }
    }
    return results;
  }

  public calculateRoofBridge(): ResultRow[] {
    const lengthLong = 2.1;
    const lengthShort = lengthLong / 2; // 1.05;
    let amountLong = 0;
    let amountShort = 0;

    this.roofBridgeLengths.forEach((len) => {
      // BK-245: Vi kräver att resten från alla jämt beräknade korta takbryggor överstiger 30cm innan det beräknas en extra
      const shortPieces = Math.ceil(Math.max(0.01, len - 0.3) / lengthShort);
      amountLong += Math.floor(shortPieces / 2);
      amountShort += Math.ceil(shortPieces % 2);
    });
    this.calcResults.roofBridgeLong = amountLong;
    this.calcResults.roofBridgeShort = amountShort;

    return [
      { art: RoofArticle.None, artnr: this.getRoofBridgeArtNo(), total: amountLong, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: this.getRoofBridgeShortArtNo(), total: amountShort, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateRoofBridgeConsoles(): ResultRow[] {
    const lengthLong = 2.1;
    const lengthShort = lengthLong / 2; // 1.05;
    let amount = 0;

    this.roofBridgeLengths.forEach((len) => {
      const shortPieces = Math.ceil(Math.max(0.01, len - 0.3) / lengthShort);
      const amountLong = Math.floor(shortPieces / 2);
      const amountShort = Math.ceil(shortPieces % 2);

      const length = amountLong * lengthLong + amountShort * lengthShort;
      amount += Math.ceil(Number((length / this.roofMultiplier()).toFixed(1))) + 1;
    });

    this.calcResults.roofBridgeConsoles = amount;

    const nPerBundle = 5;
    const bundles = Math.floor(amount / nPerBundle);
    amount = amount % nPerBundle;

    const artno = this.getRoofBridgeConsoleArtNo();
    const bundleArtno = artno + '-5';
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: bundleArtno, total: bundles, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateRoofRail(): number {
    // Varje enhet är 2.1m lång, beräkna hur många enheter det tar för att täcka alla längder
    // BK-245: Vi kräver att resten från alla jämt beräknade takfotsräcken överstiger 50cm innan det beräknas en extra
    const amount = this.hatchSafetyLengths.reduce((acc, len) => (acc += Math.ceil(Math.max(0.01, len - 0.5) / roofRailLength)), 0);
    this.calcResults.roofRail = amount;

    return amount;
  }

  public calculateRoofRailConsoles(): ResultRow[] {
    let amount = this.amountOfAccessories(this.hatchSafetyLengths, roofRailLength, 0, this.roofMultiplier());
    this.calcResults.roofRailConsoles = amount;

    const nPerBundle = 10;
    const bundles = Math.floor(amount / nPerBundle);
    amount = amount % nPerBundle;

    const artno = this.getRoofRailConsoleArtNo();
    const bundleArtno = artno + '-10';
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: bundleArtno, total: bundles, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateFootPlats(): ResultRow[] {
    let amount = this.calcResults.snowSafetyConsoles + this.calcResults.roofBridgeConsoles + this.calcResults.roofRailConsoles + this.roofRailsAmount * 4;
    this.calcResults.footPlats = amount;

    const nPerBundle = 10;
    const bundles = Math.floor(amount / nPerBundle);
    amount = amount % nPerBundle;

    const artno = this.getFootPlatArtNo();
    const bundleArtno = artno + '-10';
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: bundleArtno, total: bundles, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateSnowSlideObstacles(): number {
    const takfotslängder = this.roof.calculateTakfotslängder();

    let sum = 0;
    const snowSlideObstacleLength = 1;
    for (const längd of takfotslängder) {
      sum += Math.ceil(längd / 1000 / snowSlideObstacleLength + 1);
    }
    return sum;
  }

  public calculateSnowSlideObstacleConsoles(): number {
    const takfotslängder = this.roof.calculateTakfotslängder();

    let sum = 0;
    const snowSlideObstacleLength = 0.6;
    for (const längd of takfotslängder) {
      sum += Math.ceil(längd / 1000 / snowSlideObstacleLength + 1);
    }
    this.calcResults.snowSlideConsoles = sum;
    return sum;
  }

  public addArticleRowByArtnr(sum: ResultRow[], artnr: string, amount: number, category: Category) {
    if (amount <= 0) return;
    if ((artnr?.length ?? 0) <= 0) return;

    addResult(sum, { art: RoofArticle.None, artnr, total: amount, category: category, origins: {} });
  }

  public async calculateSAFE(): Promise<ResultRow[]> {
    if (!this.roof.mainPart) throw Error('Main roofpart not set');
    if (!this.selColor) throw Error('Color not set');
    if (this.useSAFE && !this.snowZone) throw Error('Snow-zone not set');

    let results: ResultRow[] = [];

    // Add roof-safety-system independent articles
    this.addArticleRowByArtnr(results, this.battenStepArtNo, this.selBattenSteps, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.mountingRailArtNo, this.selMountingRail, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.slipProtectionArtNo, this.selSlipProtection, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.roofHatchArtNo, this.selRoofHatch, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.safetyHookArtNo, this.selSafetyHook, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.snowHookArtNo, this.selSnowHook, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.iceStopperArtNo, this.selIceStopper, Category.Taksäkerhet);
    if (this.selSnowSlideProtection) {
      this.addArticleRowByArtnr(results, this.snowSlideObstacleArtNo, this.calculateSnowSlideObstacles(), Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, this.snowSlideObstacleKonsolArtNo, this.calculateSnowSlideObstacleConsoles(), Category.Taksäkerhet);
    }

    results = addResults(results, await this.calculateTrackTiles());

    if (this.useSAFE) {
      this.addArticleRowByArtnr(results, this.getSnowSafetyArtNo(), this.calculateSnowSafety(), Category.Taksäkerhet);
      results = addResults(results, this.calculateSnowSafetyConsoles());
      results = addResults(results, this.calculateRoofBridge());
      results = addResults(results, this.calculateRoofBridgeConsoles());
      this.addArticleRowByArtnr(results, this.getRoofRailArtNo(), this.calculateRoofRail(), Category.Taksäkerhet);
      results = addResults(results, this.calculateRoofRailConsoles());
      this.addArticleRowByArtnr(results, this.getRoofHatchArtNo(), this.roofRailsAmount, Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, this.getRoofHatchConsoleArtNo(), this.roofRailsAmount, Category.Taksäkerhet);
      results = addResults(results, this.calculateFootPlats());
      this.addArticleRowByArtnr(results, this.fasteningEyeArtNo, this.fasteningEyeAmount, Category.Taksäkerhet);
      results = addResults(results, await this.calculateSafeTrackTiles());
    }

    // Remove empty result rows
    removeZeroQtyRows(results);

    // Multiply by number of roofs
    for (const row of results) row.total *= this.roof.amount;

    return results;
  }
}

// Roof-safety specific article measures
const snowProtectionLength = 2.1;
const roofRailLength = 2.1;

export const getTileData = (tile: TileFamily): { cc: number; maxDistanceConsole: number; multiplier: number } => {
  switch (tile) {
    case TileFamily.Palema:
      return {
        cc: 300,
        maxDistanceConsole: 600,
        multiplier: 4,
      };
    case TileFamily.Exklusiv:
      return {
        cc: 250,
        maxDistanceConsole: 750,
        multiplier: 4,
      };
    case TileFamily.Carisma:
      return {
        cc: 250,
        maxDistanceConsole: 750,
        multiplier: 4,
      };
    case TileFamily.HansaFalsatLertegelRakFramkant:
    case TileFamily.HansaFalsatLertegel:
      return {
        cc: 210,
        maxDistanceConsole: 633,
        multiplier: 5,
      };
    case TileFamily.TvillingLertegelRakFramkant:
    case TileFamily.TvillingFalsatLertegel:
      return {
        cc: 346,
        maxDistanceConsole: 692,
        multiplier: 3,
      };
    case TileFamily.PianoFalsatLertegel:
      return {
        cc: 204,
        maxDistanceConsole: 612,
        multiplier: 5,
      };
  }
  console.error('MaxDistanceConsole: Unknown tile family ' + tile);
  return { cc: 0, maxDistanceConsole: 0, multiplier: 0 };
};
