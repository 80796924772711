import type { Rule } from '@/types';
import type { Roof, SecuritySystemOptions } from '@/project';
import { ValidationSteps, BattenStep, SecuritySystem, FacadeHeight } from '@/enums';

import { RoofTypeMeasurementFields } from '@/helpers/tile';
import { calc_tile, market } from '@/helpers'; // Note: This is from the 'activeRoof', but we validate all roofs here so it could have the wrong references
import {
  RoofTypeIndex,
  NockConnector,
  MIN_INPUT_LENGTH,
  MAX_INPUT_LENGTH,
  MIN_INPUT_WIDTH,
  MAX_INPUT_WIDTH,
  MIN_INPUT_WIDTH2,
  MAX_INPUT_WIDTH2,
  MIN_INPUT_SLOPE,
  MAX_INPUT_SLOPE,
  MIN_INPUT_ANGLE,
  MAX_INPUT_ANGLE,
  MIN_INPUT_LAKT,
  MAX_INPUT_LAKT,
  NockPannaVariant,
  MarketStr,
} from '@/calculation/common';

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
// import _ from "lodash";

// // stores
import pinia from '@/store/store';
import { useProjectStore } from '@/store/project';
import { useValidationStore } from '@/store/validation';

import { i18n } from '../i18n';
const { t } = i18n.global;

const { validate, stepsAreValid, stepValid, extensionsAreValid, setStepDone, setStepError } = useValidationStore(pinia);
const project = useProjectStore(pinia);
const { data } = storeToRefs(project);

const limit = {
  a: {
    min: MIN_INPUT_LENGTH / 1000,
    max: MAX_INPUT_LENGTH / 1000,
  },
  b: {
    min: MIN_INPUT_WIDTH / 1000,
    max: MAX_INPUT_WIDTH / 1000,
  },
  c: {
    min: MIN_INPUT_WIDTH2 / 1000,
    max: MAX_INPUT_WIDTH2 / 1000,
  },
  slope: {
    min: MIN_INPUT_SLOPE / 1000,
    max: MAX_INPUT_SLOPE / 1000,
  },
  angle: {
    min: MIN_INPUT_ANGLE,
    max: MAX_INPUT_ANGLE,
  },
  lathMeasurement: {
    min: MIN_INPUT_LAKT,
    max: MAX_INPUT_LAKT,
  },
};

const rules = {
  [ValidationSteps.Roof]: {
    type: {
      validator: (val: string) => {
        return [val !== RoofTypeIndex.None, t('error.validation.no_rooftype')]; // Should this be checked against an enum?
      },
    },
    a: {
      notNull: true,
      notEmpty: true,
      validator: (val: number) => {
        return [val >= limit.a.min && val <= limit.a.max, t('error.validation.out_of_range', { min: limit.a.min, max: limit.a.max })];
      },
    },
    b: {
      notNull: true,
      notEmpty: true,
      validator: (val: number) => {
        return [val >= limit.b.min && val <= limit.b.max, t('error.validation.out_of_range', { min: limit.b.min, max: limit.b.max })];
      },
    },
    c: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        if (val >= roof.roof.dimensions.b) {
          return [false, t('error.validation.c_too_big')];
        }
        return [val >= limit.c.min && val <= limit.c.max, t('error.validation.out_of_range', { min: limit.c.min, max: limit.c.max })];
      },
    },
    angle: {
      notNull: true,
      notEmpty: true,
      validator: (val: number) => {
        return [val >= limit.angle.min && val <= limit.angle.max, t('error.validation.angle_out_of_range', { min: limit.angle.min, max: limit.angle.max })];
      },
    },
    angle2: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        if (
          [RoofTypeIndex.Mansard, RoofTypeIndex.MansardHalvvalm, RoofTypeIndex.MansardValm].includes(roof.roof.type) &&
          (roof.roof.dimensions?.angle2 ?? 0) > (roof.roof.dimensions?.angle ?? 0)
        ) {
          return [false, t('error.validation.angle2_too_big')];
        }

        return [val >= limit.angle.min && val <= limit.angle.max, t('error.validation.angle_out_of_range', { min: limit.angle.min, max: limit.angle.max })];
      },
    },
    slope: {
      notNull: true,
      notEmpty: true,
      validator: (val: number) => {
        return [val >= limit.slope.min && val <= limit.slope.max, t('error.validation.out_of_range', { min: limit.slope.min, max: limit.slope.max })];
      },
    },
    slope2: {
      notNull: true,
      notEmpty: true,
      validator: (val: number) => {
        return [val >= limit.slope.min && val <= limit.slope.max, t('error.validation.out_of_range', { min: limit.slope.min, max: limit.slope.max })];
      },
    },
    facadeHeight: {
      notNull: true,
      notEmpty: false,
      validator: (val: FacadeHeight) => {
        return [market.value === MarketStr.Norge || val != FacadeHeight.None, t('error.validation.notNull')];
      },
    },
    lathDistance: {
      notNull: true,
      notEmpty: true,
    },
    lathMeasurement: {
      notNull: true,
      notEmpty: true,
      validator: (val: string | number) => {
        if (typeof val === 'number') {
          return [
            val >= limit.lathMeasurement.min && val <= limit.lathMeasurement.max,
            t('error.validation.out_of_range', { min: limit.lathMeasurement.min, max: limit.lathMeasurement.max }),
          ];
        }
        return [val === 'calculated', t('error.validation.no_lath_distance')];
      },
    },
    baseRoof: {
      notNull: true,
      notEmpty: true,
    },
  },
  [ValidationSteps.Tile]: {
    family: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    coating: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    color: {
      notNull: true,
      notEmpty: true,
      validator: (val: string) => {
        return [val !== 'None', t('error.validation.no_tile_color')];
      },
    },
    nock: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        const angle = project.isMansard(project.roofIndex) ? roof.roof.dimensions.angle2??0 : roof.roof.dimensions.angle;
        const extensionTypes = roof.extensions.map(ext => ext.roof.type);
        const nockOptions = calc_tile.value?.nockOptions?.(roof.roof.type, extensionTypes, roof.tile.coating, roof.tile.color, angle) ?? [];
        return [nockOptions.length === 0 || val !== 0, t('error.validation.no_tile_nock')];
      },
    },
    nockExtra: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        const nockExtras = calc_tile.value?.nockExtraOptions?.(roof.tile.nock) ?? [];
        return [nockExtras.length === 0 || val !== 0, t('error.validation.no_tile_nock')];
      },
    },
  },
  [ValidationSteps.Accessories]: {
    color: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    sealant: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        if (roof.tile.nockConnector === NockConnector.Yes && [RoofTypeIndex.Sadel, RoofTypeIndex.Mansard, RoofTypeIndex.Pulttak].includes(roof.roof.type))
          return [true, ''];
        return [val && val > 0, t('error.validation.no_sealant')];
      },
    },
    vantilation: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    fixingTile: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    fixingNock: {
      notNull: true,
      notEmpty: true,
      validator: (val: number, roof: Roof) => {
        if (roof.tile.nock === NockPannaVariant.None) return [true, '']; // Don't validate if nock is not used
        return [val && val > 0, t('error.validation.no_fixing_nock')];
      },
    },
  },
  [ValidationSteps.RoofSecurity]: {
    battenStep: {
      notNull: true,
      notEmpty: true,
      validator: (val: { type: BattenStep; amount: number }, roof: Roof) => {
        const angle = project.getRoofMeasurements(project.getRoofIndexById(roof.id)).angle;
        return [angle.max >= 55 || (val.type !== BattenStep.None && val.amount >= 0), t('error.validation.no_batten_step')];
      },
    },
    mountingRails: {
      notNull: true,
      notEmpty: true,
      notZero: true,
    },
    slipProtection: {
      notNull: true,
      notEmpty: true,
    },
    roofHatch: {
      notNull: true,
      notEmpty: true,
    },
    safetyHook: {
      notNull: true,
      notEmpty: true,
    },
    snowHook: {
      notNull: true,
      notEmpty: true,
    },
    securitySystem: {
      notNull: true,
      notEmpty: true,
      validator: (val: SecuritySystemOptions) => {
        if (val.type === SecuritySystem.None) return [true, '']; // If no security system is selected, skip validation

        // Validate selections
        if (val.snowSafety.length == 0 && val.roofBridge.length == 0 && val.hatchSafety.length == 0 && val.railsAmount <= 0) {
          return [false, 'error_no_security_system_selections']; // Error is interpreted by component
        }

        // Validate Snow Zone
        if (!(val.snowZone >= 1 && val.snowZone <= 5.5)) return [false, 'error_no_snow_zone_selected']; // Error is interpreted by component

        return [true, ''];
      },
    },
  },
  [ValidationSteps.Extras]: {},
} as { [key: string]: { [key: string]: Rule } };

// Global validation watchers
export default function () {
  const debug = false;
  const log = (...msg: unknown[]) => {
    if (debug) console.log(...msg);
  };
  const warn = (...msg: unknown[]) => {
    if (debug) console.warn(...msg);
  };

  // Watchers
  watch(
    () => data.value.roofs,
    (roofs) => {
      for (let i = 0; i < roofs.length; i++) {
        log('Validating roof', `${i + 1}/${roofs.length}`);
        try {
          const { roof, extensions, tile, accessories, extras, security } = roofs[i];
          const rooftype = roof.type;
          const selector = { step: ValidationSteps.Roof, roofIndex: i };
          let activeFields = RoofTypeMeasurementFields[rooftype];

          // Validate roof type
          let values = {
            type: roof.type,
            a: roof.dimensions.a,
            b: roof.dimensions.b,
            c: roof.dimensions.c,
            angle: roof.dimensions.angle,
            slope: roof.dimensions.slope,
            angle2: roof.dimensions.angle2,
            slope2: roof.dimensions.slope2,
            facadeHeight: roof.dimensions.facadeHeight,
            // exposedWeather: roof.dimensions.exposedWeather,
            lathDistance: roof.dimensions.lathDistance,
            lathMeasurement: roof.dimensions.lathDistance !== 'fixed' ? 'calculated' : roof.dimensions.lathMeasurement,
            baseRoof: roof.dimensions.baseRoof,
          } as { [key: string]: unknown };

          for (const key in values) {
            // reset value in store
            if (!activeFields.includes(key)) delete values[key];
          }

          let errors = validate({ selector, rules: rules[ValidationSteps.Roof] }, values, roofs[i]);

          // Validate lath distance
          if (errors === null) {
            setStepDone({ step: ValidationSteps.Roof, roofIndex: i });
          } else {
            setStepError({ step: ValidationSteps.Roof, roofIndex: i });
            warn('Roof is invalid', errors);
          }

          // Validate extensions
          for (let j = 0; j < extensions.length; j++) {
            const extension = extensions[j].roof;
            const extensiontype = extension.type;
            const selector = { step: ValidationSteps.Roof, roofIndex: i, extensionIndex: j };
            activeFields = RoofTypeMeasurementFields[extensiontype];

            values = {
              type: extension.type,
              a: extension.dimensions.a,
              b: extension.dimensions.b,
              c: extension.dimensions.c,
              angle: extension.dimensions.angle,
              slope: extension.dimensions.slope,
              lathDistance: extension.dimensions.lathDistance,
              lathMeasurement: extension.dimensions.lathDistance !== 'fixed' ? 'calculated' : extension.dimensions.lathMeasurement,
              baseRoof: extension.dimensions.baseRoof,
            };

            for (const key in values) {
              if (!activeFields.includes(key)) delete values[key];
            }

            // Validate roof type
            errors = validate({ selector: selector, rules: rules[ValidationSteps.Roof] }, values, extensions[j]);

            if (errors === null) {
              setStepDone({ step: ValidationSteps.Roof, roofIndex: i, extensionIndex: j });
            } else {
              setStepError({ step: ValidationSteps.Roof, roofIndex: i, extensionIndex: j });
              warn('Extension is invalid', errors);
            }
          }
          // Validate roof security
          errors = validate({ selector: { step: ValidationSteps.RoofSecurity, roofIndex: i }, rules: rules[ValidationSteps.RoofSecurity] }, security, roofs[i]);

          if (errors === null) {
            log(`Roof '${i}' 'RoofSecurity' is valid`);
            setStepDone({ step: ValidationSteps.RoofSecurity, roofIndex: i });
          } else {
            log(`Roof '${i}' 'RoofSecurity' is invalid`, errors);
            setStepError({ step: ValidationSteps.RoofSecurity, roofIndex: i });
          }

          // Validate roof tile
          errors = validate({ selector: { step: ValidationSteps.Tile, roofIndex: i }, rules: rules[ValidationSteps.Tile] }, tile, roofs[i]);
          if (errors === null) {
            log(`Roof '${i}' 'Tile' is valid`);
            setStepDone({ step: ValidationSteps.Tile, roofIndex: i });
          } else {
            log(`Roof '${i}' 'Tile' is invalid`, errors);
            setStepError({ step: ValidationSteps.Tile, roofIndex: i });
          }

          // Validate roof accessories
          errors = validate(
            { selector: { step: ValidationSteps.Accessories, roofIndex: i }, rules: rules[ValidationSteps.Accessories] },
            accessories,
            roofs[i],
          );
          if (errors === null) {
            log(`Roof '${i}' 'Accessories' is valid`);
            setStepDone({ step: ValidationSteps.Accessories, roofIndex: i });
          } else {
            log(`Roof '${i}' 'Accessories' is invalid`, errors);
            setStepError({ step: ValidationSteps.Accessories, roofIndex: i });
          }

          // Validate roof extras
          errors = validate({ selector: { step: ValidationSteps.Extras, roofIndex: i }, rules: rules[ValidationSteps.Extras] }, extras, roofs[i]);
          if (errors === null) {
            log(`Roof '${i}' 'Extras' is valid`);
            setStepDone({ step: ValidationSteps.Extras, roofIndex: i });
          } else {
            log(`Roof '${i}' 'Extras' is invalid`, errors);
            setStepError({ step: ValidationSteps.Extras, roofIndex: i });
          }

          // Update roof validation status
          if (stepValid({ step: ValidationSteps.Roof, roofIndex: i }) && extensionsAreValid(i, ValidationSteps.Roof)) {
            log(`Roof and its extensions '${i}' are valid`);
            setStepDone({ step: ValidationSteps.RoofAndExtensions, roofIndex: i });
          } else {
            log(`Roof and its extensions '${i}' are invalid`);
            setStepError({ step: ValidationSteps.RoofAndExtensions, roofIndex: i });
          }
        } catch (error) {
          console.error('Error validating roof', error);
        }
      }

      // Check if all roofs are valid
      // Note: This might be a bit too simple, as it doesn't account for if the roof/extension hasn't been checked
      // But that shouldn't ever really happen as we do them above
      if (stepsAreValid(ValidationSteps.RoofAndExtensions)) {
        log('All roofs are valid');
        setStepDone({ step: ValidationSteps.Roof });
      } else {
        log('Not all roofs are valid');
        setStepError({ step: ValidationSteps.Roof });
      }

      // Check if all roofs has valid roofsecurity selections
      if (stepsAreValid(ValidationSteps.RoofSecurity)) {
        log('All roofs have valid roof security selections');
        setStepDone({ step: ValidationSteps.RoofSecurity });
      }
    },
    { deep: true },
  );
}
